<template>
  <div class="visual_containers">
    <!-- PC 버전에서만 비디오 표시 -->
    <video
      v-if="isDesktop"
      class="inactive_820w"
      autoplay
      playsinline
      loop
      muted
      preload="none"
      crossorigin="anonymous"
    >
      <source src="@/assets/img/main/main1_looping.webm" type="video/webm" />
      <source src="@/assets/img/main/main1_looping.mp4" type="video/mp4" />
    </video>
    <video
      v-if="isDesktop"
      class="active_820w"
      autoplay
      playsinline
      loop
      muted
      preload="none"
      crossorigin="anonymous"
    >
      <source src="@/assets/img/main/main1_mobile.webm" type="video/webm" />
      <source src="@/assets/img/main/main1_mobile.mp4" type="video/mp4" />
    </video>

    <!-- 모바일 버전에서 이미지 표시 -->
    <img
      v-else
      class="mobile-image"
      src="@/assets/img/main/main1_mobile.png"
      alt="Mobile Background"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "Visual",
  methods: {},
  setup() {
    const desktopSize = window.innerWidth > 768;
    const isDesktop = ref(desktopSize);

    const updateIsDesktop = () => (isDesktop.value = desktopSize);

    onMounted(() => {
      window.addEventListener("resize", updateIsDesktop);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateIsDesktop);
    });

    return { isDesktop };
  },
});
</script>

<style scoped lang="css">
/* Default */

.visual_containers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
}

video,
.mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .mob_video {
  display: none;
} */

/*__Default*/

/* Animation */
/*__Animation*/

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

/* 모바일에서 비디오는 숨기고 이미지만 보여줌 */
@media screen and (max-width: 768px) {
  video {
    display: none;
  }

  .mobile-image {
    display: block;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/*__반응형*/
</style>
