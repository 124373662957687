
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "Visual",
  methods: {},
  setup() {
    const desktopSize = window.innerWidth > 768;
    const isDesktop = ref(desktopSize);

    const updateIsDesktop = () => (isDesktop.value = desktopSize);

    onMounted(() => {
      window.addEventListener("resize", updateIsDesktop);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateIsDesktop);
    });

    return { isDesktop };
  },
});
