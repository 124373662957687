
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";
import vue100vh from "@/components/Vue100vh.vue";

export default defineComponent({
  name: "About",
  data() {
    return {
      show0: false,
      theme: Theme.white,
    };
  },
  components: {
    ThemeObserver,
    vue100vh,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
      this.theme = Theme.white;
      if (value) {
        setTimeout(() => {
          this.theme = Theme.dark;
        }, 1000);
      }
    },
  },
  setup() {
    const desktopSize = window.innerWidth > 768;
    const isDesktop = ref(desktopSize);

    const updateIsDesktop = () => (isDesktop.value = desktopSize);

    onMounted(() => {
      window.addEventListener("resize", updateIsDesktop);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateIsDesktop);
    });

    return { isDesktop };
  },
});
