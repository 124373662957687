
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";
import vue100vh from "@/components/Vue100vh.vue";

export default defineComponent({
  name: "Home",
  data() {
    return {
      show0: false,
      theme: Theme.hide,
    };
  },
  components: {
    ThemeObserver,
    vue100vh,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    scrollToSection(section: string) {
      // 스크롤 이동
      this.$nextTick(() => {
        const target = document.querySelector(`#${section}`);
        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
});
