import { createRouter, createWebHistory, RouterScrollBehavior } from "vue-router";
import WorkDetail from "@/views/WorksDetail.vue";
import Main from "@/views/Main.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/works/:projectName",
    name: "WorkDetail",
    component: WorkDetail,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.querySelector(to.hash) as HTMLElement | null;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        return { top: element.offsetTop };
      }
    }
    return { top: 0 };
  },
});

export default router;
