
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import useDirectionIntersectionObserver from "@/composables/useDirectionIntersectionObserver";

export default defineComponent({
  name: "ScrollObserver",
  setup() {
    const el = ref(null);
    const { observe, unobserve, isShown, direction } = useDirectionIntersectionObserver();

    onMounted(() => {
      observe(el.value);
    });

    onBeforeUnmount(() => {
      unobserve(el.value);
    });

    return { el, isShown, direction };
  },
  watch: {
    direction(value) {
      // console.log("direction : " + value);
      this.$emit("changeDirection", value);
    },
  },
  methods: {},
});
